
export function getMainModels(id) {
    if (id) {
        return getSameChannelModels(id).sort();
    }

    return [{
        "id": "34",
        "label": "百度 ERNIE-Bot-turbo"
    }, {
        "id": "108",
        "label": "讯飞 SparkDesk3.5"
    }, {
        "id": "45",
        "label": "360智脑 360GPT_S2_V9"
    }, {
        "id": "78",
        "label": "阿里通义千问 qwen-max"
    }, {
        "id": "99",
        "label": "智谱 glm-4"
    }, {
        "id": "121",
        "label": "零一万物 yi-34b-chat-200k"
    }, {
        "id": "67",
        "label": "百川 Baichuan2-53B"
    }, {
        "id": "83",
        "label": "月之暗面 moonshot-v1-8k"
    }, {
        "id": "5",
        "label": "联网搜索"
    }, {
        "id": "7",
        "label": "其他"
    }]
}

export function getSameChannelModels(id) {
    var models = getAllModelInfos();
    if (id < 0) {
        return models.map(({ id, label }) => ({ id, label }));
    }

    const model = models.find(model => model.id === id);
    if (!model) return [];

    const channelType = model.channelType;
    return models.filter(model => model.channelType === channelType)
        .map(({ id, label }) => ({ id, label }));
}

export function getCheepModel(cur_model) {
    const validModels = [
        "advanced-voice",
        "suno-v3",
        "suno-v3.5",
        "udio130-v1.5",
        "udio32-v1.5",
        "chirp-v3-0",
        "chirp-v3-5"  , 
        "chirp-v2-xxl-alpha",
        "sora-1.0-turbo",
        "sora-1:1-720p-5s", 
        "sora-1:1-480p-5s", 
        "sora-16:9-480p-5s", 
        "sora-9:16-480p-5s", 
        "sora-1:1-480p-10s", 
        "sora-16:9-480p-10s", 
        "sora-9:16-480p-10s", 
        "sora-16:9-720p-5s", 
        "sora-9:16-720p-5s", 
        "luma-video",
        "luma-vip-video",
        "luma-pro-video",
        "pika-1.5",
        "pika-text-to-video",
        "domo-img-to-video",
        "domo-video-to-video",
        "runway-vip-video",
        "kling-video-std-5s",
        "vidu-video",
        "vidu-vip-video",
        "whisper-1",
        "tts-1",
        "tts-1-hd",
        "pixverse", 
        "pixverse-vip", 
        
        "net-gpt-3.5-turbo-16k",
        "net-gpt-4",

        "grok-beta",
        "grok-2-1212", 

        'gpt-4',
        'gpt-4-0613',
        'gpt-4-0314',
        'gpt-4-32k',
        'gpt-4-32k-0613',
        'gpt-4-preview-0125',
        'gpt-4-32k-0314',
        'gpt-4-all',
        "gpt-4-turbo",
        "gpt-4-turbo-2024-04-09",
        "gpt-4-turbo-preview",
        "gpt-4-0125-preview",
        "gpt-4-1106-preview",
        "gpt-4-1106-vision-preview",
        "gpt-4-gizmo-g",
        'gpt-4-v',
        "gpt-4-vision-preview",

        "gpt-4o",
        "gpt-4o-2024-08-06",
        "gpt-4o-mini",
        "gpt-4o-all",
        "gpt-4o-2024-05-13",

        "chatgpt-4o-latest",

        "o1-mini",
        "o1-mini-all",
        "o1-mini-2024-09-12",
        "o1-preview",
        "o1-preview-all",
        "o1-preview-2024-09-12",

        "st-gpt-4o",
        "yuanqi",

        "deepseek-coder",
        "dall-e-3",
        "dall-e-2",
        "gemini-pro-vision",
        "gemini-1.5-flash-latest",
        "gemini-1.5-pro-latest",

        'gpt-4-dalle',
        'mj',
        'mj-chat',
        'midjourney',
        'ideogram',
        "playground-v2.5",
        "stable-diffusion-3-2b",
        'flux',

        'flux-1-dev-fp8',
        'flux-1-schnell-fp8',
        'flux-1-schnell',
        'flux-1-dev',
        'sd3.5-medium',
        'sd3.5-large',
        'sd3.5-large-turbo',
        'ssd-1b',
        'stable-diffusion-xl-1024-v1-0',
        'playground-v2-1024px-aesthetic',
        'playground-v2-5-1024px-aesthetic',
        'japanese-stable-diffusion-xl',

        'claude-2',
        'claude-3',
        "claude-3-opus-20240229",
        "claude-3-sonnet-20240229",
        "claude-3-haiku-20240307",
        "claude-3-5-sonnet-20240620",
        "claude-3-5-sonnet-20241022",
        "claude-3-5-sonnet-all",

        "yi-34b-chat-200k",
        "yi-vl-plus",
        "yi-large",
        "yi-medium",
        "yi-vision",
        "yi-medium-200k",
        "yi-large-rag",
        "yi-large-fc",
        "yi-large-turbo",
        "yi-large-preview",

        'characterglm',
        "search-gpts",
        "search-gpts-chat",
        "gpts-search",
        "text-search-ada-doc-001",
        "text-search-ada-doc-001",
        "embedding_s1_v1",
        "text-embedding-ada-002",
        "Embedding-V1",
        "embedding-001",
        "embedding-bert-512-v1",
        "embedding_s1_v1",
        "embedding-gecko-001",
        "embedding-gecko-002",
        "text-embedding-3-small",
        "text-embedding-3-large",
        "Baichuan4",
        "Baichuan3-Turbo-128k",
        "glm-4",
        "glm-4-alltools",
        "glm-4-airx",
        "glm-4-520",
        "glm-4v",
        "qwen-max",
        "ERNIE-Bot-Pro",
        "ernie-4.0",

        "pdf-to-text",
        "pdf-to-text-ocr",
        "pdf-to-text-progress",
        "pdf-to-text-progress-ocr",
        "pdf-to-json",
        "pdf-to-json-ocr"
    ];

    if (validModels.includes(cur_model) || cur_model.indexOf("gpt-4-gizmo-g") > -1 || cur_model.indexOf("yuanqi-") > -1) {
        cur_model = 'gpt-4o-mini';
    }

    return cur_model;
}

export function getModel(id) {
    var models = getAllModelInfos();
    const foundModel = models.find(model => model.id === id);
    return foundModel ? foundModel.label : null;
}

export function getModelToken(id) {
    var models = getAllModelInfos();
    const foundModel = models.find(model => model.id === id);
    return foundModel ? foundModel.max_token : 4000;
}

export function getAllModels() {
    var models = getAllModelInfos();
    return models.map(model => model.label);
}

export function getAllModelInfos() {
    var models = [
        {
            "id": "0",
            "channelType": "openai-old",
            "label": "ada",
            "max_token": 4000
        },
        {
            "id": "1",
            "channelType": "openai-old",
            "label": "babbage",
            "max_token": 4000
        },
        {
            "id": "2",
            "channelType": "openai-old",
            "label": "curie",
            "max_token": 4000
        },
        {
            "id": "3",
            "channelType": "openai-old",
            "label": "davinci",
            "max_token": 4000
        },
        {
            "id": "4",
            "channelType": "openai",
            "label": "gpt-3.5-turbo",
            "max_token": 4000
        },
        {
            "id": "5",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-0301",
            "max_token": 4000
        },
        {
            "id": "6",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-0613",
            "max_token": 4000
        },
        {
            "id": "7",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-16k",
            "max_token": 16000
        },
        {
            "id": "8",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-16k-0613",
            "max_token": 16000
        },
        {
            "id": "9",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-instruct",
            "max_token": 4000
        },
        {
            "id": "10",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-instruct-0914",
            "max_token": 4000
        },
        {
            "id": "11",
            "channelType": "openai",
            "label": "gpt-4",
            "max_token": 8000
        },
        {
            "id": "12",
            "channelType": "openai",
            "label": "gpt-4-0314",
            "max_token": 8000
        },
        {
            "id": "13",
            "channelType": "openai",
            "label": "gpt-4-0613",
            "max_token": 8000
        },
        {
            "id": "14",
            "channelType": "openai",
            "label": "gpt-4-32k",
            "max_token": 32000
        },
        {
            "id": "15",
            "channelType": "openai",
            "label": "gpt-4-32k-0314",
            "max_token": 32000
        },
        {
            "id": "16",
            "channelType": "openai",
            "label": "gpt-4-32k-0613",
            "max_token": 32000
        },
        {
            "id": "17",
            "channelType": "openai-draw",
            "label": "gpt-4-dalle",
            "max_token": 4000
        },
        {
            "id": "18",
            "channelType": "google",
            "label": "google-palm",
            "max_token": 5000
        },
        {
            "id": "19",
            "channelType": "meta",
            "label": "llama-2-70b",
            "max_token": 70000
        },
        {
            "id": "20",
            "channelType": "meta",
            "label": "llama-2-13b",
            "max_token": 13000
        },
        {
            "id": "21",
            "channelType": "meta",
            "label": "llama-2-7b",
            "max_token": 7000
        },
        {
            "id": "22",
            "channelType": "meta",
            "label": "code-llama-34b",
            "max_token": 34000
        },
        {
            "id": "23",
            "channelType": "meta",
            "label": "code-llama-13b",
            "max_token": 13000
        },
        {
            "id": "24",
            "channelType": "meta",
            "label": "code-llama-7b",
            "max_token": 7000
        },
        {
            "id": "25",
            "channelType": "claude",
            "label": "claude-1-100k",
            "max_token": 7000
        },
        {
            "id": "26",
            "channelType": "claude",
            "label": "claude-2-100k",
            "max_token": 100000
        },
        {
            "id": "27",
            "channelType": "claude",
            "label": "claude-instant-1",
            "max_token": 4000
        },
        {
            "id": "28",
            "channelType": "claude",
            "label": "claude-2",
            "max_token": 100000
        },
        {
            "id": "29",
            "channelType": "openai",
            "label": "net-gpt-3.5-turbo-16k",
            "max_token": 16000
        },
        {
            "id": "30",
            "channelType": "openai",
            "label": "net-gpt-4",
            "max_token": 8000
        },
        {
            "id": "31",
            "channelType": "draw",
            "label": "midjourney",
            "max_token": 4000
        },
        {
            "id": "32",
            "channelType": "draw",
            "label": "mj",
            "max_token": 4000
        },
        {
            "id": "33",
            "channelType": "baidu",
            "label": "ERNIE-Bot",
            "max_token": 4000
        },
        {
            "id": "34",
            "channelType": "baidu",
            "label": "ERNIE-Bot-turbo",
            "max_token": 4000
        },
        {
            "id": "35",
            "channelType": "baidu",
            "label": "ERNIE-Bot-Pro",
            "max_token": 4000
        },
        {
            "id": "36",
            "channelType": "chatglm",
            "label": "chatglm_pro",
            "max_token": 4000
        },
        {
            "id": "37",
            "channelType": "chatglm",
            "label": "chatglm_std",
            "max_token": 4000
        },
        {
            "id": "38",
            "channelType": "chatglm",
            "label": "chatglm_lite",
            "max_token": 4000
        },
        {
            "id": "39",
            "channelType": "ali",
            "label": "qwen-v1",
            "max_token": 1500
        },
        {
            "id": "40",
            "channelType": "ali",
            "label": "qwen-plus-v1",
            "max_token": 2000
        },
        {
            "id": "41",
            "channelType": "openai",
            "label": "semantic_similarity_s1_v1",
            "max_token": 4000
        },
        {
            "id": "42",
            "channelType": "xunfei",
            "label": "SparkDesk",
            "max_token": 1800
        },
        {
            "id": "43",
            "channelType": "xunfei",
            "label": "SparkDesk2",
            "max_token": 1800
        },
        {
            "id": "44",
            "channelType": "xunfei",
            "label": "SparkDesk3",
            "max_token": 1800
        },
        {
            "id": "45",
            "channelType": "360",
            "label": "360GPT_S2_V9",
            "max_token": 1800
        },
        {
            "id": "46",
            "channelType": "360",
            "label": "360GPT_S2_V9.4",
            "max_token": 4000
        },
        {
            "id": "47",
            "channelType": "openai",
            "label": "dall-e",
            "max_token": 4000
        },
        {
            "id": "48",
            "channelType": "openai",
            "label": "text-embedding-ada-002",
            "max_token": 4000
        },
        {
            "id": "49",
            "channelType": "openai",
            "label": "text-davinci-003",
            "max_token": 4000
        },
        {
            "id": "50",
            "channelType": "openai",
            "label": "text-davinci-002",
            "max_token": 4000
        },
        {
            "id": "51",
            "channelType": "openai",
            "label": "text-ada-001",
            "max_token": 4000
        },
        {
            "id": "52",
            "channelType": "openai",
            "label": "text-babbage-001",
            "max_token": 4000
        },
        {
            "id": "53",
            "channelType": "openai",
            "label": "text-curie-001",
            "max_token": 4000
        },
        {
            "id": "54",
            "channelType": "openai",
            "label": "text-davinci-edit-001",
            "max_token": 4000
        },
        {
            "id": "55",
            "channelType": "openai",
            "label": "text-moderation-latest",
            "max_token": 4000
        },
        {
            "id": "56",
            "channelType": "openai",
            "label": "text-moderation-stable",
            "max_token": 4000
        },
        {
            "id": "57",
            "channelType": "openai",
            "label": "code-davinci-edit-001",
            "max_token": 4000
        },
        {
            "id": "58",
            "channelType": "openai",
            "label": "text-search-ada-doc-001",
            "max_token": 4000
        },
        {
            "id": "59",
            "channelType": "openai-tts",
            "label": "whisper-1",
            "max_token": 4000
        },
        {
            "id": "60",
            "channelType": "openai",
            "label": "Embedding-V1",
            "max_token": 4000
        },
        {
            "id": "61",
            "channelType": "openai",
            "label": "PaLM-2",
            "max_token": 4000
        },
        {
            "id": "62",
            "channelType": "openai",
            "label": "embedding-bert-512-v1",
            "max_token": 4000
        },
        {
            "id": "63",
            "channelType": "openai",
            "label": "embedding_s1_v1",
            "max_token": 4000
        },
        {
            "id": "64",
            "channelType": "openai",
            "label": "gpt-4-bing",
            "max_token": 4000
        },
        {
            "id": "65",
            "channelType": "chatglm",
            "label": "ChatGLM_Turbo",
            "max_token": 4000
        },
        {
            "id": "66",
            "channelType": "chatglm",
            "label": "characterglm",
            "max_token": 1800
        },
        {
            "id": "67",
            "channelType": "Baichuan",
            "label": "Baichuan2-53B",
            "max_token": 1800
        },
        {
            "id": "68",
            "channelType": "chatglm",
            "label": "chatglm",
            "max_token": 4000
        },
        {
            "id": "69",
            "channelType": "openai",
            "label": "gpt-4-all",
            "max_token": 128000
        },
        {
            "id": "70",
            "channelType": "openai",
            "label": "gpt-4-v",
            "max_token": 4000
        },
        {
            "id": "71",
            "channelType": "stable-diffusion",
            "label": "stable-diffusion",
            "max_token": 4000
        },
        {
            "id": "72",
            "channelType": "openai",
            "label": "gpt-4-1106-preview",
            "max_token": 128000
        },
        {
            "id": "73",
            "channelType": "openai",
            "label": "gpt-4-1106-vision-preview",
            "max_token": 128000
        },
        {
            "id": "74",
            "channelType": "openai-tts",
            "label": "tts-1",
            "max_token": 4000
        },
        {
            "id": "75",
            "channelType": "openai-tts",
            "label": "tts-1-hd",
            "max_token": 4000
        },
        {
            "id": "76",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-1106",
            "max_token": 16000
        },
        {
            "id": "77",
            "channelType": "openai",
            "label": "gpt-4-gizmo-g",
            "max_token": 32000
        },
        {
            "id": "78",
            "channelType": "ali",
            "label": "qwen-max",
            "max_token": 2000
        },
        {
            "id": "79",
            "channelType": "ali",
            "label": "qwen-turbo",
            "max_token": 1500
        },
        {
            "id": "80",
            "channelType": "ali",
            "label": "qwen-plus",
            "max_token": 2000
        },
        {
            "id": "81",
            "channelType": "ali",
            "label": "qwen-max-v1",
            "max_token": 2000
        },
        {
            "id": "82",
            "channelType": "openai",
            "label": "gpt-4-vision-preview",
            "max_token": 4000
        },
        {
            "id": "83",
            "channelType": "kimi",
            "label": "moonshot-v1-8k",
            "max_token": 4000
        },
        {
            "id": "84",
            "channelType": "kimi",
            "label": "moonshot-v1-32k",
            "max_token": 4000
        },
        {
            "id": "85",
            "channelType": "kimi",
            "label": "moonshot-v1-128k",
            "max_token": 4000
        },
        {
            "id": "86",
            "channelType": "openai",
            "label": "dall-e-3",
            "max_token": 4000
        },
        {
            "id": "87",
            "channelType": "tencent",
            "label": "hunyuan",
            "max_token": 4000
        },
        {
            "id": "88",
            "channelType": "openai",
            "label": "dall-e-2",
            "max_token": 4000
        },
        {
            "id": "89",
            "channelType": "openai",
            "label": "gpt-4-turbo",
            "max_token": 128000
        },
        {
            "id": "90",
            "channelType": "openai",
            "label": "chat-bison-00",
            "max_token": 4000
        },
        {
            "id": "91",
            "channelType": "openai",
            "label": "embedding-001",
            "max_token": 4000
        },
        {
            "id": "92",
            "channelType": "openai",
            "label": "embedding-gecko-001",
            "max_token": 4000
        },
        {
            "id": "93",
            "channelType": "openai",
            "label": "embedding-gecko-002",
            "max_token": 4000
        },
        {
            "id": "94",
            "channelType": "openai",
            "label": "text-bison-001",
            "max_token": 4000
        },
        {
            "id": "95",
            "channelType": "google",
            "label": "gemini-pro",
            "max_token": 8000
        },
        {
            "id": "96",
            "channelType": "google",
            "label": "gemini-pro-vision",
            "max_token": 8000
        },
        {
            "id": "97",
            "channelType": "google",
            "label": "gemini-ultra",
            "max_token": 4000
        },
        {
            "id": "98",
            "channelType": "openai",
            "label": "aqa",
            "max_token": 4000
        },
        {
            "id": "99",
            "channelType": "chatglm",
            "label": "glm-4",
            "max_token": 128000
        },
        {
            "id": "100",
            "channelType": "openai",
            "label": "mixtral-8x7b",
            "max_token": 32000
        },
        {
            "id": "101",
            "channelType": "draw",
            "label": "mj-chat",
            "max_token": 4000
        },
        {
            "id": "102",
            "channelType": "openai",
            "label": "gpt-3.5-turbo-0125",
            "max_token": 16000
        },
        {
            "id": "103",
            "channelType": "openai",
            "label": "text-embedding-3-small",
            "max_token": 4000
        },
        {
            "id": "104",
            "channelType": "openai",
            "label": "text-embedding-3-large",
            "max_token": 4000
        },
        {
            "id": "105",
            "channelType": "openai",
            "label": "gpt-4-turbo-preview",
            "max_token": 128000
        },
        {
            "id": "106",
            "channelType": "openai",
            "label": "gpt-4-0125-preview",
            "max_token": 128000
        },
        {
            "id": "107",
            "channelType": "openai",
            "label": "text-moderation-007",
            "max_token": 4000
        },
        {
            "id": "108",
            "channelType": "xunfei",
            "label": "SparkDesk3.5",
            "max_token": 1800
        },
        {
            "id": "109",
            "channelType": "openai",
            "label": "search-gpts-chat",
            "max_token": 4000
        },
        {
            "id": "110",
            "channelType": "openai",
            "label": "search-gpts",
            "max_token": 4000
        },
        {
            "id": "111",
            "channelType": "openai",
            "label": "gpts-search",
            "max_token": 4000
        },
        {
            "id": "112",
            "channelType": "openai",
            "label": "gpts-get",
            "max_token": 4000
        },
        {
            "id": "113",
            "channelType": "openai",
            "label": "mistral-medium",
            "max_token": 32000
        },
        {
            "id": "114",
            "channelType": "ali",
            "label": "qwen-72b",
            "max_token": 32000
        },
        {
            "id": "115",
            "channelType": "openai",
            "label": "sora-1.0-turbo",
            "max_token": 4000
        },
        {
            "id": "116",
            "channelType": "claude",
            "label": "claude-3",
            "max_token": 100000
        },
        {
            "id": "117",
            "channelType": "claude",
            "label": "claude-3-opus-20240229",
            "max_token": 100000
        },
        {
            "id": "118",
            "channelType": "claude",
            "label": "claude-3-sonnet-20240229",
            "max_token": 100000
        },
        {
            "id": "119",
            "channelType": "claude",
            "label": "claude-3-haiku-20240307",
            "max_token": 100000
        },
        {
            "id": "120",
            "channelType": "yi",
            "label": "yi-34b-chat-0205",
            "max_token": 4000
        },
        {
            "id": "121",
            "channelType": "yi",
            "label": "yi-34b-chat-200k",
            "max_token": 4000
        },
        {
            "id": "122",
            "channelType": "yi",
            "label": "yi-vl-plus",
            "max_token": 4000
        },
        {
            "id": "123",
            "channelType": "pika",
            "label": "pika-text-to-video",
            "max_token": 4000
        },
        {
            "id": "124",
            "channelType": "domo",
            "label": "domo-img-to-video",
            "max_token": 4000
        },
        {
            "id": "125",
            "channelType": "suno",
            "label": "suno-v3",
            "max_token": 4000
        },
        {
            "id": "126",
            "channelType": "wbot",
            "label": "wbot-2",
            "max_token": 4000
        },
        {
            "id": "127",
            "channelType": "kimi",
            "label": "kimi",
            "max_token": 4000
        },
        {
            "id": "128",
            "channelType": "step",
            "label": "step",
            "max_token": 4000
        },
        {
            "id": "129",
            "channelType": "step",
            "label": "step-1-200k",
            "max_token": 4000
        },
        {
            "id": "130",
            "channelType": "step",
            "label": "step-1v-32k",
            "max_token": 4000
        },
        {
            "id": "131",
            "channelType": "deepseek",
            "label": "deepseek-chat",
            "max_token": 32000
        },
        {
            "id": "132",
            "channelType": "deepseek",
            "label": "deepseek-coder",
            "max_token": 16000
        },
        {
            "id": "133",
            "channelType": "openai",
            "label": "gpt-4o",
            "max_token": 128000
        },
        {
            "id": "135",
            "channelType": "chirp",
            "label": "chirp-v3-0",
            "max_token": 4000
        },
        {
            "id": "136",
            "channelType": "chirp",
            "label": "chirp-v2-xxl-alpha",
            "max_token": 4000
        },
        {
            "id": "137",
            "channelType": "domo",
            "label": "domo-video-to-video",
            "max_token": 4000
        },
        {
            "id": "138",
            "channelType": "tencent",
            "label": "yuanqi-Cm2yPJjfGhtr",
            "max_token": 4000
        },
        {
            "id": "139",
            "channelType": "tencent",
            "label": "yuanqi-rIUozU0pemV2",
            "max_token": 4000
        },
        {
            "id": "140",
            "channelType": "tencent",
            "label": "yuanqi-mdQAL8rzCYDN",
            "max_token": 4000
        },
        {
            "id": "141",
            "channelType": "Baichuan",
            "label": "Baichuan3-Turbo",
            "max_token": 1800
        },
        {
            "id": "142",
            "channelType": "Baichuan",
            "label": "Baichuan4",
            "max_token": 1800
        },
        {
            "id": "143",
            "channelType": "Baichuan",
            "label": "Baichuan3-Turbo-128k",
            "max_token": 1800
        },
        {
            "id": "144",
            "channelType": "Baichuan",
            "label": "Baichuan2-Turbo",
            "max_token": 1800
        },
        {
            "id": "145",
            "channelType": "Baichuan",
            "label": "Baichuan2-Turbo-192k",
            "max_token": 1800
        },
        {
            "id": "146",
            "channelType": "baidu",
            "label": "ERNIE-Speed",
            "max_token": 4000
        },
        {
            "id": "147",
            "channelType": "baidu",
            "label": "ERNIE-Lite",
            "max_token": 4000
        },
        {
            "id": "148",
            "channelType": "baidu",
            "label": "ERNIE-Tiny",
            "max_token": 4000
        },
        {
            "id": "149",
            "channelType": "tencent",
            "label": "yuanqi-vlLakgxQ54Kv",
            "max_token": 4000
        },
        {
            "id": "150",
            "channelType": "chatglm",
            "label": "glm-4-air",
            "max_token": 8000
        },
        {
            "id": "151",
            "channelType": "chatglm",
            "label": "glm-4-520",
            "max_token": 128000
        },
        {
            "id": "152",
            "channelType": "tencent",
            "label": "hunyuan-lite",
            "max_token": 4000
        },
        {
            "id": "153",
            "channelType": "xunfei",
            "label": "general",
            "max_token": 4000
        },
        {
            "id": "154",
            "channelType": "xunfei",
            "label": "generalv3",
            "max_token": 4000
        },
        {
            "id": "155",
            "channelType": "xunfei",
            "label": "generalv3.5",
            "max_token": 4000
        },
        {
            "id": "156",
            "channelType": "google",
            "label": "gemini-1.5-pro-latest",
            "max_token": 8000
        },
        {
            "id": "157",
            "channelType": "google",
            "label": "gemini-1.5-flash-latest",
            "max_token": 8000
        },
        {
            "id": "158",
            "channelType": "luma",
            "label": "luma-video",
            "max_token": 4000
        },
        {
            "id": "159",
            "channelType": "suno",
            "label": "suno-v3.5",
            "max_token": 4000
        },
        {
            "id": "160",
            "channelType": "claude",
            "label": "claude-3-5-sonnet-20240620",
            "max_token": 100000
        },
        {
            "id": "161",
            "channelType": "openai",
            "label": "gpt-4o-all",
            "max_token": 128000
        },
        {
            "id": "162",
            "channelType": "xunfei",
            "label": "generalv2",
            "max_token": 4000
        },
        {
            "id": "163",
            "channelType": "xunfei",
            "label": "generalv4",
            "max_token": 4000
        },
        {
            "id": "164",
            "channelType": "openai",
            "label": "gpt-4o-mini",
            "max_token": 128000
        },
        {
            "id": "165",
            "channelType": "chatglm",
            "label": "glm-4-alltools",
            "max_token": 128000
        },
        {
            "id": "166",
            "channelType": "openai",
            "label": "st-gpt-4o",
            "max_token": 128000
        },
        {
            "id": "167",
            "channelType": "playground",
            "label": "playground-v2.5",
            "max_token": 4000
        },
        {
            "id": "168",
            "channelType": "stable-diffusion",
            "label": "stable-diffusion-3-2b",
            "max_token": 4000
        },
        {
            "id": "169",
            "channelType": "meta",
            "label": "llama-3.1-8b",
            "max_token": 32000
        },
        {
            "id": "170",
            "channelType": "meta",
            "label": "llama-3.1-70b",
            "max_token": 32000
        },
        {
            "id": "171",
            "channelType": "meta",
            "label": "llama-3.1-405b",
            "max_token": 32000
        },
        {
            "id": "172",
            "channelType": "360",
            "label": "360gpt-pro",
            "max_token": 4000
        },
        {
            "id": "173",
            "channelType": "360",
            "label": "360gpt2-pro",
            "max_token": 4000
        },
        {
            "id": "174",
            "channelType": "360",
            "label": "360gpt-turbo-responsibility-8k",
            "max_token": 4000
        },
        {
            "id": "175",
            "channelType": "360",
            "label": "360gpt-turbo",
            "max_token": 4000
        },
        {
            "id": "176",
            "channelType": "vidu",
            "label": "vidu-vip-video",
            "max_token": 4000
        },
        {
            "id": "177",
            "channelType": "vidu",
            "label": "vidu-video",
            "max_token": 4000
        },
        {
            "id": "178",
            "channelType": "flux",
            "label": "flux",
            "max_token": 4000
        },
        {
            "id": "179",
            "channelType": "openai",
            "label": "gpt-4o-2024-08-06",
            "max_token": 128000
        },
        {
            "id": "180",
            "channelType": "runway",
            "label": "runway-video",
            "max_token": 4000
        },
        {
            "id": "181",
            "channelType": "chatglm",
            "label": "glm-4-long",
            "max_token": 1000000
        },
        {
            "id": "182",
            "channelType": "draw",
            "label": "ideogram",
            "max_token": 4000
        },
        {
            "id": "183",
            "channelType": "openai",
            "label": "o1-mini",
            "max_token": 128000
        },
        {
            "id": "184",
            "channelType": "openai",
            "label": "o1-preview",
            "max_token": 128000
        },
        {
            "id": "185",
            "channelType": "openai",
            "label": "o1-mini-all",
            "max_token": 128000
        },
        {
            "id": "186",
            "channelType": "openai",
            "label": "o1-preview-all",
            "max_token": 128000
        },
        {
            "id": "187",
            "channelType": "step",
            "label": "step-2",
            "max_token": 16000
        },
        {
            "id": "188",
            "channelType": "step",
            "label": "step-2-16k",
            "max_token": 16000
        },
        {
            "id": "189",
            "channelType": "step",
            "label": "step-1",
            "max_token": 8000
        },
        {
            "id": "190",
            "channelType": "step",
            "label": "step",
            "max_token": 8000
        },
        {
            "id": "191",
            "channelType": "chatglm",
            "label": "glm-4-flash",
            "max_token": 128000
        },
        {
            "id": "192",
            "channelType": "ali",
            "label": "qwen-long",
            "max_token": 5000000
        },
        {
            "id": "193",
            "channelType": "claude",
            "label": "claude-3-5-sonnet-all",
            "max_token": 100000
        },
        {
            "id": "194",
            "channelType": "claude",
            "label": "claude-3-5-sonnet-20241022",
            "max_token": 100000
        },
        {
            "id": "195",
            "channelType": "openai",
            "label": "advanced-voice",
            "max_token": 1500
        },
        {
            "id": "196",
            "channelType": "luma",
            "label": "luma-pro-video",
            "max_token": 4000
        },
        {
            "id": "197",
            "channelType": "luma",
            "label": "luma-vip-video",
            "max_token": 4000
        },
        {
            "id": "198",
            "channelType": "pika",
            "label": "pika-1.5",
            "max_token": 4000
        },
        {
            "id": "199",
            "channelType": "pika",
            "label": "pika-text-to-video",
            "max_token": 4000
        },
        {
            "id": "200",
            "channelType": "runway",
            "label": "runway-vip-video",
            "max_token": 4000
        },
        {
            "id": "201",
            "channelType": "kling",
            "label": "kling-video-std-5s",
            "max_token": 4000
        },
        {
            "id": "201",
            "channelType": "openai",
            "label": "chatgpt-4o-latest",
            "max_token": 4000
        },
        {
            "id": "202",
            "channelType": "openai",
            "label": "o1-mini-2024-09-12",
            "max_token": 4000
        },
        {
            "id": "203",
            "channelType": "openai",
            "label": "o1-preview-2024-09-12",
            "max_token": 4000
        },
        {
            "id": "204",
            "channelType": "yi",
            "label": "yi-lightning",
            "max_token": 16000
        },
        {
            "id": "205",
            "channelType": "yi",
            "label": "yi-large",
            "max_token": 32000
        },
        {
            "id": "206",
            "channelType": "yi",
            "label": "yi-large-turbo",
            "max_token": 16000
        },
        {
            "id": "207",
            "channelType": "yi",
            "label": "yi-large-rag",
            "max_token": 16000
        },
        {
            "id": "208",
            "channelType": "yi",
            "label": "yi-large-fc",
            "max_token": 32000
        },
        {
            "id": "209",
            "channelType": "yi",
            "label": "yi-medium",
            "max_token": 16000
        },
        {
            "id": "210",
            "channelType": "yi",
            "label": "yi-vision",
            "max_token": 16000
        },
        {
            "id": "211",
            "channelType": "yi",
            "label": "yi-medium-200k",
            "max_token": 200000
        },
        {
            "id": "212",
            "channelType": "yi",
            "label": "yi-spark",
            "max_token": 16000
        },
        {
            "id": "213",
            "channelType": "yi",
            "label": "yi-large-preview",
            "max_token": 16000
        },
        {
            "id": "214",
            "channelType": "XAI",
            "label": "grok-beta",
            "max_token": 16000
        },
        {
            "id": "215",
            "channelType": "flux",
            "label": "flux-1-dev",
            "max_token": 4000
        },
        {
            "id": "216",
            "channelType": "flux",
            "label": "flux-1-schnell",
            "max_token": 4000
        },
        {
            "id": "217",
            "channelType": "flux",
            "label": "flux-1-dev-fp8",
            "max_token": 4000
        },
        {
            "id": "218",
            "channelType": "flux",
            "label": "flux-1-schnell-fp8",
            "max_token": 4000
        },
        {
            "id": "218",
            "channelType": "stable-diffusion",
            "label": "sd3.5-medium",
            "max_token": 4000
        },
        {
            "id": "219",
            "channelType": "stable-diffusion",
            "label": "sd3.5-large",
            "max_token": 4000
        },
        {
            "id": "220",
            "channelType": "stable-diffusion",
            "label": "sd3.5-large-turbo",
            "max_token": 4000
        },
        {
            "id": "221",
            "channelType": "stable-diffusion",
            "label": "ssd-1b",
            "max_token": 4000
        },
        {
            "id": "222",
            "channelType": "stable-diffusion",
            "label": "stable-diffusion-xl-1024-v1-0",
            "max_token": 4000
        },
        {
            "id": "223",
            "channelType": "playground",
            "label": "playground-v2-1024px-aesthetic",
            "max_token": 4000
        },
        {
            "id": "224",
            "channelType": "playground",
            "label": "playground-v2-5-1024px-aesthetic",
            "max_token": 4000
        },
        {
            "id": "225",
            "channelType": "stable-diffusion",
            "label": "japanese-stable-diffusion-xl",
            "max_token": 4000
        },
        {
            "id": "226",
            "channelType": "suno",
            "label": "udio130-v1.5",
            "max_token": 4000
        },
        {
            "id": "227",
            "channelType": "suno",
            "label": "udio32-v1.5",
            "max_token": 4000
        },
        {
            "id": "228",
            "channelType": "shusheng",
            "label": "internlm2.5-latest",
            "max_token": 4000
        },
        {
            "id": "229",
            "channelType": "pixverse",
            "label": "pixverse",
            "max_token": 1000
        },
        {
            "id": "230",
            "channelType": "pixverse",
            "label": "pixverse-vip",
            "max_token": 1000
        },
        {
            "id": "231",
            "channelType": "XAI",
            "label": "grok-2-1212",
            "max_token": 128000
        },
        {
            "id": "232",
            "channelType": "openai",
            "label": "sora-1:1-720p-5s",
            "max_token": 1000
        },
        {
            "id": "233",
            "channelType": "openai",
            "label": "sora-1:1-480p-5s",
            "max_token": 1000
        },
        {
            "id": "234",
            "channelType": "openai",
            "label": "sora-16:9-480p-5s",
            "max_token": 1000
        },
        {
            "id": "235",
            "channelType": "openai",
            "label": "sora-9:16-480p-5s",
            "max_token": 1000
        },
        {
            "id": "236",
            "channelType": "openai",
            "label": "sora-1:1-480p-10s",
            "max_token": 1000
        },
        {
            "id": "237",
            "channelType": "openai",
            "label": "sora-16:9-480p-10s",
            "max_token": 1000
        },
        {
            "id": "238",
            "channelType": "openai",
            "label": "sora-9:16-480p-10s",
            "max_token": 1000
        },
        {
            "id": "239",
            "channelType": "openai",
            "label": "sora-16:9-720p-5s",
            "max_token": 1000
        },
        {
            "id": "240",
            "channelType": "openai",
            "label": "sora-9:16-720p-5s",
            "max_token": 1000
        },
        {
            "id": "241",
            "channelType": "chirp",
            "label": "chirp-v3-5",
            "max_token": 1000
        }
    ];

    return models.sort((a, b) => {
        return a.label.localeCompare(b.label);
    });
}